@import '../../assets/scss/config';
@import '../../assets//scss/global/functions';

.kt-portlet__head {
  .kt-portlet__head-icon {
    @each $name, $value in $kt-state-colors {
      &.#{kt-font-#{$name}} {
        svg g [fill] {
          fill: map-get($value, 'base')
        }
      }
    }
  }
}