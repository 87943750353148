//
// Button Customization
//

// Base button
.btn {
  background: transparent;
  outline: none !important;
  vertical-align: middle;

  &:focus:not(.btn-elevate) {
    box-shadow: none !important;
  }
}

// Tags
label.btn,
input.btn,
button.btn {
  cursor: pointer;
}

// Clean button
.btn.btn-clean {
  border-color: transparent;
  background: transparent;
  color: kt-base-color(shape, 3);

  i {
    color: kt-base-color(shape, 3);
  }

  .show > &,
  &.active,
  &:active,
  &:hover {
    color: kt-brand-color();
    background: kt-base-color(shape, 1);

    i {
      color: kt-brand-color();
    }
  }
}

// Default button
.btn.btn-default {
  border: 0;
  background: transparent;
  color: kt-base-color(label, 2);
  border: 1px solid kt-base-color(shape, 2);

  i {
    color: kt-base-color(shape, 3);
  }

  .show > &,
  &.active,
  &:active,
  &:hover {
    i {
      color: kt-brand-color(inverse);
    }

    color: kt-brand-color(inverse);
    background: kt-brand-color();
    border-color: kt-brand-color();
  }
}

// Secondary button
.btn-secondary {
  background-color: transparent;
  border: 1px solid $input-border-color;
  color: $btn-secondary-color;

  .show > &.dropdown-toggle,
  &:focus,
  &.active,
  &:active,
  &:hover {
    color: $btn-secondary-color;
    border-color: $input-border-color;
    background-color: $btn-secondary-hover-bg-color;
  }
}

// Button states
@each $name, $color in join($kt-state-colors, $kt-social-colors) {
  .btn-#{$name} {
    @include button-variant(kt-get($color, base), kt-get($color, base));
    color: kt-get($color, inverse);

    @if $name == light {
      .show > &.dropdown-toggle,
      &:focus,
      &.active,
      &:active,
      &:hover {
        background-color: kt-base-color(grey, 1);
        border-color: kt-base-color(grey, 1);
      }
    }

    & + .btn.dropdown-toggle {
      position: relative;

      &:before {
        display: block;
        content: ' ';
        border-left: 1px solid kt-get($color, inverse);
        position: absolute;
        top: 3px;
        left: -1px;
        bottom: 3px;
        opacity: 0.3;
      }

      &:hover,
      &:focus,
      &.active {
        &:before {
          display: none;
        }
      }
    }
  }
}

// Outline states
@each $name, $color in $kt-state-colors {
  .btn-outline-#{$name} {
    @include button-outline-variant(
      kt-get($color, base),
      kt-get($color, inverse)
    );
  }
}

// Hover states
@each $name, $color in $kt-state-colors {
  .btn-hover-#{$name} {
    //background: transparent;
    //color: kt-get($color, base);

    .show > &,
    &.active,
    &:active,
    &:hover {
      color: kt-get($color, inverse);
      background-color: kt-get($color, base);
      border-color: kt-get($color, base);
    }
  }

  .btn-outline-hover-#{$name} {
    //background: transparent;

    &.btn-clean {
      border: 1px solid transparent;
    }

    .show > &,
    &.active,
    &:active,
    &:hover {
      color: kt-get($color, base);
      border-color: kt-get($color, base);
      background-color: transparent;
    }
  }
}

// Font states
@each $name, $color in $kt-state-colors {
  .btn-font-#{$name} {
    color: kt-get($color, base);
  }

  .btn-font-hover-#{$name} {
    .show > &,
    &.active,
    &:active,
    &:hover {
      color: kt-get($color, base);
    }
  }
}

// Secondary outline button
.btn.btn-outline-secondary {
  color: darken(kt-get($theme-colors, secondary), 50%);

  .show > &,
  &.active,
  &:active,
  &:hover {
    background-color: kt-get($theme-colors, secondary);
    //color: #fff;
  }
}

// Elevate buttons
.btn {
  // Elevate 1
  &.btn-elevate {
    transition: all 0.3s ease 0s;

    &.btn-elevate-air {
      &:hover {
        transform: translate(0, -4px);
      }
    }
  }

  @each $name, $color in $kt-state-colors {
    $base-color: kt-get($color, base);

    @if $name == light {
      $base-color: #999999;
    }

    &.btn-#{$name}.btn-elevate {
      box-shadow: 0px 4px 16px 0px rgba($base-color, 0.15);

      .show > &,
      &:focus,
      &.active,
      &:active,
      &:hover {
        box-shadow: 0px 9px 16px 0px rgba($base-color, 0.25) !important;

        &.btn-sm {
          box-shadow: 0px 6px 16px 0px rgba($base-color, 0.25) !important;
        }

        @if $name == light {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }

    &.btn-#{$name}.btn-elevate-hover {
      .show > &,
      &.active,
      &:focus,
      &:active,
      &:hover {
        box-shadow: 0px 9px 16px 0px rgba($base-color, 0.25) !important;

        &.btn-sm {
          box-shadow: 0px 6px 16px 0px rgba($base-color, 0.25) !important;
        }

        @if $name == light {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }
}

// Square buttons
.btn {
  &.btn-square {
    border-radius: 0;
  }
}

// Pill buttons
.btn {
  &.btn-pill {
    border-radius: 2rem;
  }
}

// Text transform
.btn {
  &.btn-upper {
    text-transform: uppercase;
  }

  &.btn-bold {
    font-weight: 600;
  }

  &.btn-bolder {
    font-weight: 600;
  }

  &.btn-boldest {
    font-weight: 700;
  }

  &.btn-thin {
    font-weight: 400;
  }

  &.btn-lower {
    text-transform: lowercase;
  }
}

// Custom sizing
.btn {
  &.btn-wide {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  &.btn-wider {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  &.btn-widest {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &.btn-tall {
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
  }

  &.btn-taller {
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
  }

  &.btn-tallest {
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
  }
}

// Custom font sizing
.btn {
  &.btn-font-sm {
    font-size: 0.85rem;
  }

  &.btn-font-md {
    font-size: 1rem;
  }

  &.btn-font-lg {
    font-size: 1.1rem;
  }
}

// Button with icon
.btn {
  //display: inline-flex;
  align-items: center;

  @include kt-icons-size(
    (
      lineawesome: 1.5rem,
      fontawesome: 1.2rem,
      flaticon: 1.2rem,
    )
  );

  i {
    padding-right: 0.5rem;
    vertical-align: middle;
    //line-height: 0;
  }

  &.btn-sm {
    @include kt-icons-size(
      (
        lineawesome: 1.3rem,
        fontawesome: 1rem,
        flaticon: 1rem,
      )
    );

    i {
      margin-right: 0.35rem;
      padding: 0;
    }
  }

  &.btn-lg {
    @include kt-icons-size(
      (
        lineawesome: 1.7rem,
        fontawesome: 1.4rem,
        flaticon: 1.3rem,
      )
    );

    i {
      margin-right: 0.75rem;
      padding: 0;
    }
  }
}

// Label button
.btn.btn-label {
  background-color: kt-base-color(shape, 1);
  color: kt-brand-color();
  cursor: text !important;
}

.btn.btn-link,
a.btn.btn-label,
button.btn.btn-label,
input.btn.btn-label {
  cursor: pointer !important;

  &:focus,
  &:hover,
  &.active {
    background-color: kt-brand-color();
    color: kt-brand-color(inverse);
  }
}

// Label button states
@each $name, $color in join($kt-state-colors, $kt-social-colors) {
  .btn.btn-label-#{$name} {
    background-color: rgba(kt-get($color, base), 0.1);
    color: kt-get($color, base);
    cursor: text !important;
  }

  .btn.btn-label-#{$name}-o2 {
    background-color: rgba(kt-get($color, base), 0.2);
    color: kt-get($color, base);
    cursor: text !important;
  }

  label.btn.btn-label-#{$name},
  a.btn.btn-label-#{$name},
  button.btn.btn-label-#{$name},
  input.btn.btn-label-#{$name} {
    cursor: pointer !important;
    &:focus,
    &:hover,
    &.active {
      background-color: kt-get($color, base);
      color: kt-get($color, inverse);
    }
  }
}

// Button with icon only
.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &.btn-circle {
    border-radius: 50%;
  }

  i {
    padding: 0;
    margin: 0;
    //line-height: 0;
  }

  [class^='socicon-'],
  [class*=' socicon-'] {
    line-height: 1;
  }

  & {
    height: 3rem;
    width: 3rem;
  }

  &.btn-sm {
    height: 2.5rem;
    width: 2.5rem;
  }

  &.btn-lg {
    height: 4rem;
    width: 4rem;
  }

  // Icon sizing
  &.btn-icon-sm {
    @include kt-icons-size(
      (
        lineawesome: 1.25rem,
        fontawesome: 1.15rem,
        flaticon: 1.15rem,
      )
    );
  }

  // Icon sizing
  &.btn-icon-md {
    @include kt-icons-size(
      (
        lineawesome: 1.3rem,
        fontawesome: 1.2rem,
        flaticon: 1.2rem,
      )
    );
  }

  &.btn-icon-lg {
    @include kt-icons-size(
      (
        lineawesome: 1.4rem,
        fontawesome: 1.4rem,
        flaticon: 1.4rem,
      )
    );
  }

  // Icon button states
  @each $name, $color in $kt-state-colors {
    &.btn-#{$name} {
      i {
        color: kt-get($color, inverse);
      }

      &:focus,
      &:hover,
      &.active {
        i {
          color: kt-get($color, inverse);
        }
      }
    }
  }
}

// Same height with icon btn
.btn-icon-h {
  & {
    height: 3rem;
  }

  &.btn-sm {
    height: 2.25rem;
  }

  &.btn-lg {
    height: 4rem;
  }
}

// Input group workarounds
.input-group-append,
.input-group-prepend {
  .btn.btn-icon {
    height: auto;
  }
}

// Button with spinner
.btn.kt-spinner {
  & {
    &:not(.kt-spinner--center) {
      padding-left: 3 * $input-btn-padding-x;

      &:before {
        left: $input-btn-padding-x;
      }
    }

    // Right
    &.kt-spinner--right {
      padding-left: $input-btn-padding-x;
      padding-right: 3 * $input-btn-padding-x;

      &:before {
        left: auto;
        right: $input-btn-padding-x;
      }
    }
  }

  &.kt-spinner--sm {
    &:not(.kt-spinner--center) {
      padding-left: 2.5 * $input-btn-padding-x;

      &:before {
        left: $input-btn-padding-x;
      }
    }

    // Right
    &.kt-spinner--right {
      padding-left: $input-btn-padding-x;
      padding-right: 2.5 * $input-btn-padding-x;

      &:before {
        left: auto;
        right: $input-btn-padding-x;
      }
    }
  }

  &.kt-spinner--lg {
    &:not(.kt-spinner--center) {
      padding-left: 3.5 * $input-btn-padding-x;

      &:before {
        left: $input-btn-padding-x;
      }
    }

    // Right
    &.kt-spinner--right {
      padding-left: $input-btn-padding-x;
      padding-right: 3.5 * $input-btn-padding-x;

      &:before {
        left: auto;
        right: $input-btn-padding-x;
      }
    }
  }
}

// Button glow style
.btn {
  &.btn-glow {
    box-shadow: 0px 2px 14px 2px rgba(255, 255, 255, 0.1);

    .show > &,
    &.active,
    &:active,
    &:hover {
      box-shadow: 0px 2px 14px 2px rgba(255, 255, 255, 0.3);
    }
  }
}
