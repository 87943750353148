@import '../../vendors/flaticon/scss/flaticon.scss';
@import '../../vendors/flaticon2/scss/flaticon.scss';
@import '../../vendors/line-awesome/scss/line-awesome.scss';
@import '../../vendors/socicon/scss/socicon.scss';
@import '../../vendors/summernote/scss/summernote.scss';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';