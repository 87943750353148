@import '../../assets/scss/config';
@import '../../assets//scss/global/functions';

$transition-animation-function: cubic-bezier(0.22, 1, 0.36, 1);
$icon-color: kt-get($kt-state-colors, 'brand', 'base');

.kt-menu__submenu {
  transition: max-height 0.4s $transition-animation-function;
}

.kt-menu__item--open-dropdown.kt-menu__item--active {
  > .kt-menu__link {
    > .kt-menu__link-icon {
      color: $icon-color !important;
      svg g [fill] {
        fill: $icon-color !important;
      }
    }
  }
}

.kt-aside-menu {
  .kt-menu__nav {
    .kt-menu__item {
      &--open-enter {
        > .kt-menu__heading > .kt-menu__ver-arrow:before,
        > .kt-menu__link > .kt-menu__ver-arrow:before {
          transform: rotateZ(90deg);
        }
      }

      &--open-leave {
        > .kt-menu__heading > .kt-menu__ver-arrow:before,
        > .kt-menu__link > .kt-menu__ver-arrow:before {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open-enter > .kt-menu__heading > .kt-menu__ver-arrow:before,
// .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open-enter > .kt-menu__link > .kt-menu__ver-arrow:before {
//   transform: rotateZ(90deg);
// }

// // .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open-leave {
// //   > .kt-menu__submenu {
// //     max-height: 0 !important;
// //     overflow: hidden;
// //   }
// // }

// .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open-leave > .kt-menu__heading > .kt-menu__ver-arrow:before,
// .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open-leave > .kt-menu__link > .kt-menu__ver-arrow:before {
//   transform: translate3d(0, 0, 0);
// }